<template>
<div>
 <v-scale-screen :fullScreen="false" width="1920" height="1080">
  <div class="newScreen">
      <!-- 顶部 -->
        <div class="top">
          <div class="top_title">农业数据分析</div>
        </div>
        
        <div class="cont">
            <!-- 左中 -->
        <div class="contLeft">
            <div class="nav_left">
              <div class="flex_space flex_align" style="margin-left: 46px">
                <div class="nav_left_item" v-for="(item,index) in leftList" :key="index" @click="showMode(item)">
                    <img class="nav_left_img" :src="item.icon"/>
                    <div class="nav_left_name">{{item.name}}</div>
                    <div class="nav_left_num">{{ item.num || 0 }}</div>
                </div>
            </div>
          </div>
          <div class="nsLeft">
            
            <div class="left-center">
                <div class="flex_space flex_center_align" style="margin-left:24px">
                  <div class="lc_title">种植</div>
                </div>
                <div class="line"></div>
                <div class="panel" style="height: 350px;margin-bottom: 30px;">
                  <div style="width: 340px;height: 350px;margin: 20px;overflow: auto;">
                    <div style="width: 50%;float: left;">
                      <div class="panel_title">人口动态</div>
                      <div style="height: 2px;background: linear-gradient(90deg, #DAD6DA 0%, rgba(13, 24, 54, 0) 100%);margin-bottom: 5px;"></div>
                      <p>苹果</p>
                      <p>南国梨</p>
                      <p>小麦</p>
                      <p>向日葵</p>
                    </div>
                    <div style="width: 50%;float: left;">
                      <div class="panel_title">人口动态</div>
                      <div style="height: 2px;background: linear-gradient(90deg, #DAD6DA 0%, rgba(13, 24, 54, 0) 100%);margin-bottom: 5px;"></div>
                      <p>小平房村村林丰南国梨</p>
                      <p>古香食杂店</p>
                    </div>
                    <div style="width: 340px;height: 100px;background: linear-gradient(90deg, #1C2D5C 0%, rgba(13, 24, 54, 0) 49%);float: left;border-radius: 15px;">

                    </div>
                    <div style="width: 340px;height: 250px;background-color: palegoldenrod;float: left;"></div>
                  </div>
                </div>
            </div>

            <div class="left-center">
                <div class="flex_space flex_center_align" style="margin-left:24px">
                  <div class="lc_title">养殖</div>
                  <img class="lc_right" src="./assets/img/rarrow.png"/>
                </div>
                <div class="line"></div>
                <div class="panel" style="height: 350px;margin-bottom: 30px;">
                  <div style="width: 340px;height: 350px;background-color: red;margin: 20px;overflow: auto;">
                    <div style="width: 50%;height: 100px;background-color: green;float: left;">
                      <div class="panel_title">人口动态</div>
                      <div class="p_line"></div>
                    </div>
                    <div style="width: 50%;height: 100px;background-color: yellowgreen;float: left;"></div>
                    <div style="width: 340px;height: 100px;background-color: gold;float: left;"></div>
                    <div style="width: 340px;height: 250px;background-color: palegoldenrod;float: left;"></div>
                  </div>
                </div>
            </div>

          </div>
        </div>
        <div class="contCenter">
          <div class="nscenter">
            <!-- 地图 -->
            <div class="mapCss mapCity">
              <div ref="mapBox" style="width:100%;height:100%"> </div>
            </div>
          </div>
        </div>

            <!-- 右 -->
        <div class="contRight">
          <div class="nsright">
            <div class="left-center">
                <div class="flex_space flex_center_align">
                  <div class="lc_title">土地使用</div>
                </div>
                <div class="line"></div>
                  <div class="panel">
                    <div style="width: 320px;height: 834px;margin: 20px auto;background-color: red;" >
                        
                    </div>
                </div>
            </div>
          </div>
        </div>  
    </div>
  </div>
  <newMode ref="newMode"></newMode>
 </v-scale-screen>
</div>
</template>
<script>
import newMode from '@/components/newMode.vue'
import { mapOption, barOptions, newlineOption } from './utils/options.js'
import { getIndexStatistics, postLogin, getDangIndex, getLineClass, articleInfoList, getBankList } from '@/api/api.js'
import * as echarts from 'echarts'
const mapData = require('./utils/newcun.json')

export default {
  components: {
    newMode
  },
  data() {
    return {
      leftList: [
        {
          name: '民众心声',
          icon: require('./assets/img/imz.png'),
          type: '3',
          ptype: 'voxPop',
          num: 0
        },
        {
          name: '全民广场',
          icon: require('./assets/img/iqm.png'),
          type: '2',
          ptype: 'nationalSquare',
          num: 0
        },
        {
          name: '在线课堂',
          icon: require('./assets/img/isk.png'),
          type: '1',
          ptype: 'readBook',
          num: 0
        },
        {
          name: '道德银行',
          icon: require('./assets/img/ibank.png'),
          type: '4',
          ptype: 'releaseReq',
          num: 0
        },
        {
          name: '贵客到访',
          icon: require('./assets/img/car.png'),
          type: '2',
          ptype: 'monitorCar',
          num: 0
        }
      ],
      zpajList: [{ fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }, { fullName: '品种' }],
      total: { familyNum: 0, residentNum: 0, adminUserNum: 0, oftenNum: 0, foreignNum: 0, flowNum: 0, rangerNum: 0, impatientNum: 0 },
      partyNums: {},
      memberCount: {},
      groupList: [],
      baseform: {}
    }
  },
  methods: {
    goHome() {
      this.$router.replace('/')
    },
    initChart() {
      // 地图绘制
      echarts.registerMap('xiaopingfang', mapData)
      let map = echarts.init(this.$refs.mapBox, null, { renderer: 'svg' })
      map.clear()
      map.setOption(mapOption)

      barOptions.xAxis.data = ['泰达砖厂', '小平房铁矿', '万昆矿业', '永山车队', '秀源果业']
      barOptions.yAxis.axisLine.lineStyle.color = '#fff'
      // barOptions.yAxis.axisLabel.textStyle.fontSize = 7
      barOptions.grid.x = 40
      barOptions.grid.bottom = 20
      barOptions.yAxis.name = '万元'
      barOptions.series[0] = {
        type: 'bar',
        showBackground: false,
        barMaxWidth: 16, // 每一个都要设置
        barMinWidth: 5, // 每一个都要设置
        itemStyle: {
          barBorderRadius: [6, 6, 6, 6],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: '#FFFFFF' }, { offset: 1, color: '#0063B8' }]),
          emphasis: {
            label: {
              show: true,
              position: 'top',
              color: '#fff'
            }
          }
        },
        data: ['2000', '3000', '8000', '150', '1500']
      }
      let chart = echarts.init(this.$refs.industry, null, { renderer: 'svg' })
      chart.clear()
      chart.setOption(barOptions)

      newlineOption.title.text = '天秀山'

      let linenew = echarts.init(this.$refs.saleLine, null, { renderer: 'svg' })
      linenew.clear()
      linenew.setOption(newlineOption)

      let linen = echarts.init(this.$refs.people, null, { renderer: 'svg' })
      linen.clear()
      linen.setOption(newlineOption)
    },
    drowCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#F9B814', '#9523CB'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['常住人口', '外来人口', '流动人口']
        },
        series: [
          {
            name: '人口',
            type: 'pie',
            radius: ['75%', '90%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [{ value: data.oftenNum, name: '常住人口' }, { value: data.foreignNum, name: '外来人口' }, { value: data.flowNum, name: '流动人口' }]
          }
        ]
      }
      let cricle = echarts.init(this.$refs.circle, null, { renderer: 'svg' })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    getgrids() {
      getIndexStatistics().then(res => {
        if (res.code == 0) {
          this.total = res.items[0]
          this.$nextTick(() => {
            this.drowCircle(res.items[0])
          })
        }
      })
    },
    showMode(item) {
      console.log(item)
      if (['3', '2', '5'].includes(item.type)) {
        this.searchArticles(item.type, item)
      } else if (item.type == '4') {
        // 道德银行
        this.searchBnakList(item)
      } else if (item.type == '1') {
        getLineClass().then(res => {
          if (res.code == '0') {
            if (!res.model.length) {
              this.$message.error('暂无数据')
              return
            }
            let newData = {
              form: item,
              list: res.model
            }
            this.$refs.newMode.show = true
            this.$refs.newMode.initData(newData)
          }
        })
      }
    },
    searchArticles(type, data) {
      let params = {
        townId: 6281,
        page: 1,
        rows: 100000,
        articleType: 1,
        articleSubtype: type
      }
      articleInfoList(params).then(res => {
        if (res.code == '0') {
          this.articleList = res.items
          if (!res.items.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: data,
            articleList: res.items
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    searchBnakList(item) {
      getBankList().then(res => {
        if (res.code == '0') {
          if (!res.model.length) {
            this.$message.error('暂无数据')
            return
          }
          let newData = {
            form: item,
            list: res.model
          }
          this.$refs.newMode.show = true
          this.$refs.newMode.initData(newData)
        }
      })
    },
    // 登录
    submitForm(formName) {
      let form = {
        username: 'xiaopingfang',
        password: '123456'
      }
      postLogin(form)
        .then(res => {
          if (res.code == '0') {
            // sessionStorage.clear()
            this.resetSetItem('userInfo', JSON.stringify(res.model))
            sessionStorage.setItem('token', res.model.tokenValue)
            this.searchDetails(res.model.regionId)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchDetails(regionId) {
      getDangIndex({ villageId: regionId }).then(res => {
        if (res.code == 0) {
          // this.totalNums = res.model.memberCount
          // this.$nextTick(() => {
          //   this.drowFunction(res.model)
          // })
        }
      })
    }
  },
  mounted() {
    this.submitForm()
    this.$nextTick(() => {
      this.initChart()
      this.getgrids()
    })
  }
}
</script>
<style lang="scss" scope>
@import '@/common/css/inter';
</style>